import type { Seo } from "~/types/seo";

export function mapSeo(gqlSeo?: Seo | null): Seo {
  return {
    title: gqlSeo?.title ?? "Grape Law Firm PLLC",
    description: gqlSeo?.description,
    robots: gqlSeo?.robots?.filter((item): item is string => item !== null) ?? null,
    openGraph: {
      type: gqlSeo?.openGraph?.type ?? "website",
      title: gqlSeo?.openGraph?.title,
      description: gqlSeo?.openGraph?.description,
      image: {
        secureUrl: gqlSeo?.openGraph?.image?.secureUrl,
      },
      articleMeta: {
        publishedTime: gqlSeo?.openGraph?.articleMeta?.publishedTime,
        modifiedTime: gqlSeo?.openGraph?.articleMeta?.modifiedTime,
      },
      updatedTime: gqlSeo?.openGraph?.articleMeta?.modifiedTime,
    },
  };
}
